<template>
  <div v-if="!dataFetch" class="test">
    <v-card outlined class="my-5 mt-10">
      <v-card-text class="profilAgencyAvatar">
        <v-card max-width="fit-content" color="EoleBlue" class="rounded-pill pr-2" flat>
          <v-card-title style="color: white !important; display: inline-block">
            <i class="fa-solid fa-building mr-2 ml-2" style="font-size: 1.75rem"></i>
            {{ agency.name }}
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text class="mt-16">
        <v-row v-if="!!agency">
          <v-col cols="12" md="6" class="text-left">
            <strong>Code NAF/APE :</strong> {{ agency.naf_code }} <br />
            <strong>Forme juridique :</strong> {{ agency.juridique_forme }} <br />

            <strong>Date de création :</strong> {{ agency.creation_date_formated }} <br />
            <strong>Nom commercial :</strong> {{ agency.brand_name }} <br />
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <strong> Numéro de siren : </strong> {{ agency.siret }} <br />
            <v-spacer> </v-spacer>
            <h3>
              <a @click="showMap(`${agency.street} ${agency.zip} ${agency.city}`)"
                >{{ agency.street }}
                <br />
                {{ agency.zip }}, {{ agency.city }}</a
              >
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <lawyer-due></lawyer-due>
  </div>
  <div v-else>
    <custome-loader></custome-loader>
    <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    <v-row class="mt-5">
      <v-col cols="12" md="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="8">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LawyerDue from "./components/LawyerDue.vue"
import moment from "moment"
import config from "@/views/config/config"

// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import io from "socket.io-client"
import { getCookie } from "@/utils/cookies"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: "Lawyer",
  data: () => ({
    countCandidates: 0,
    user: {},
    socialStatements: [],
    collaboratorsHistory: [],
    test: [],
    testMonthLeave: [],
    supervisedMembersIds: [],
    newCostCount: 0,
    newDemandCount: 0,
    dataFetch: false,
    dialogTrainingDocuments: false,
    selectedTraining: {},
    currentIdAccount: null,
    currentYear: moment().format("YYYY"),
    editedUploadTraining: {
      lastname: "",
      firstname: "",
    },
    year: moment().format("YYYY"),
    dialogEditTraining: false,
    demandHistory: [],
    agencyInformations: {},
    headersHRFiles: [
      {
        text: "Nom du fichier",
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: "Date de téléversement",
        value: "format_date_upload",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    headersFiles: [
      {
        text: "Nom du fichier",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Date de téléversement",
        value: "format_date_upload",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    collaboratorsCompensatoryLeave: [],
    collaboratorsRecoveryLeave: [],
    collaboratorsValidation: [],
    collaboratorsHoliday: [],
    demand: [],
    selectedServices: null,
    newDescription: "",
    showInactive: false,
    fileUrlTraining: null,
    documentTraining: {
      name: null,
      data: {
        name: null,
        type: null,
      },
      categorie: null,
    },
    dialogUploadDocument: false,
    testData: [
      {
        name: "FRICHOT ROMAN",
        date: "12/12/2021",
        duration: "1 jour",
        type: "Congés payés",
        days: "1 jour",
        period: "Du 01/01/2023 au 31/01/2023",
        state: "En attente",
        end_date: "12/12/2021",
        comment: "Je souhaite prendre un jour de congés",
        sold: 5,
      },
    ],
    headersHolidays: [
      {
        text: "Collaborateur",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Date de la demande",
        value: "date",
      },
      {
        text: "Période souhaitée",
        value: "period",
      },
      {
        text: "Type de congés",
        value: "type",
      },
      {
        text: "Solde à date",
        value: "sold",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    newTraining: {
      collaborators: [],
      date: "",
      isDDA: false,
      designation: "",
      duration: "",
      organism: "",
    },
    dialogAddTraining: false,
    headersTrainingsItemsFiles: [
      {
        text: "Organisme",
        value: "organism",
        sortable: false,
      },
      {
        text: "Titre de la formation",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Date",
        value: "display_date",
        sortable: false,
      },
      {
        text: "Durée",
        value: "display_duration",
        sortable: false,
      },
      {
        text: "Éligible DDA",
        value: "dda",
        sortable: false,
      },
      {
        text: "Documents",
        value: "documents",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    expandedTrainingItems: [],
    headersTrainingsItems: [
      // {
      //   text: "Année de formation",
      //   value: "year",
      //   sortable: true,
      // },
      {
        text: "Organisme",
        value: "organism",
        sortable: false,
      },
      {
        text: "Titre de la formation",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Date",
        value: "display_date",
        sortable: false,
      },
      {
        text: "Durée",
        value: "display_duration",
        sortable: false,
      },
      // {
      //   text: "Éligible DDA",
      //   value: "dda",
      //   sortable: false,
      // },
      {
        text: "Documents",
        value: "documents",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    expandedTraining: [],
    headersTrainings: [
      {
        text: "NOM COLLABORATEUR",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "HEURES DDA PROGRAMMÉES",
        value: "sum_DDA_hours",
      },
      {
        text: "HEURES DDA RÉALISÉES",
        value: "sum_DDA_hours_done",
      },
      {
        text: "FORMATION TOTALE",
        value: "sum_hours",
      },
      {
        text: "COMPLÉTUDE D'INFORMATIONS",
        value: "data_complete",
        align: "center",
        sortable: false,
      },
      {
        text: "VOIR",
        align: "center",
        value: "state",
        sortable: false,
      },
    ],
    initialCoordinates: [48.413008, -1.742288], // Coordonnées initiales du centre de la carte
    tileLayerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", // URL du serveur de tuiles OSM

    tabProcess: "tab-1",
    etablishmentsOptions: [
      {
        id: 999,
        name: "Tous les établissements",
      },
    ],
    missingFields: [],
    documentArea: {
      type: 0,
      area: "",
      subType: "",
    },
    obligatoryFilesCount: {
      value: 0,
      max: 0,
      percentage: 0,
    },
    obligatoryInformations: {
      value: 0,
      max: 0,
      percentage: 0,
      fields: [],
    },
    editFile: {},
    dialogUpload: {
      dialog: false,
      type: 0,
      area: "",
      subType: "",
    },
    dialogUploadPostFile: false,
    editServiceManage: {},
    dialogEditService: false,
    expanded: [],
    chartDataService: {},
    nodeChartService: [],
    config: config,
    dialogPutService: false,
    collaboratorsInService: [],
    stepperAddService: 1,
    editService: {
      idAgencyEtablishment: null,
      idManager: null,
      // idService: null,
      name: "",
    },
    dialogAddService: false,
    document: {
      title: "",
      name: "",
      data: {
        name: "",
        type: "",
      },
      id_agency_etablishment: 999,
    },
    loadImage: false,
    selectedItem: 0,
    items: [
      {
        href: "tab-1",
        icon: "mdi-file",
        text: "Mes docs",
      },
      {
        href: "tab-2",
        icon: "mdi-file",
        text: "Mes recrut",
      },
    ],
    agency: null,
    pdf: null,
    fetchServicesState: false,
    valueOrganization: 0,
    tabHR: "tab-1",
    dialog: false,
    treeConfig: {
      nodeWidth: 150,
      nodeHeight: 50,
      levelHeight: 250,
    },

    chartData: {},
    fetchMembers: false,
    tab: null,

    collectiveAgreements: [],
    hover: false,
    services: [],
    servicesProposed: [],
    etablishments: [],
    fileUrl: null,
    pappersInformations: [],
    directorIdentities: [],
    directorName: [],
    agencyName: null,
    load: false,
    ipError: false,
    ip: "",
    ipLocation: "",
    /* test purpose*/
    active: [],
    avatar: null,
    open: [],
    users: [],
    nodeChart: [],
    dialogMembersService: false,
    waitTimeout: true,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    headersCollaborators: [
      {
        text: "Collaborateur",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Adresse personnelle",
        value: "addresse",
        sortable: false,
      },
      {
        text: "Type de contrat",
        value: "contract_designation",
        sortable: false,
      },
      {
        text: "Poste",
        value: "job_designation",
        sortable: false,
      },
      {
        text: "Nationalité",
        value: "nationality",
        align: "center",
        sortable: false,
      },
      {
        text: "Date de naissance",
        value: "birthdate_display",
        sortable: false,
      },

      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],
    headers: [
      {
        text: "NOM DU SERVICE",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "RESPONSABLE HIÉRARCHIQUE",
        value: "complete_name_manager",
      },
      {
        text: "NOMBRE DE MEMBRES",
        value: "number_members",
      },
      {
        text: "SITE",
        value: "location",
      },

      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],
    agencyPictureProfil: null,
    tabChangeIndicator: 0,
  }),
  mounted() {},
  async created() {
    this.user = this.$store.state.user
    if (this.$route.query.menu) {
      this.tab = this.$route.query.menu
    }
    this.fetchAgency().finally(() => {
      this.getPictureProfil()

      this.selectTabHR("tab-4")

      switch (this.user.grade) {
        case "manager":
          this.fetchAllServices()

          this.fetchServices()
          this.fetchManage()
          this.fetchAgencyHoliday()
          break
        case "administrateur":
          this.fetchAllServices()
          this.fetchServices()
          this.fetchManage()
          this.fetchAgencyHoliday()
          break
      }
    })

    this.agencyName = localStorage.getItem("agency")
  },
  components: {
    LawyerDue,
    CustomeLoader,
  },
  computed: {
    getActiveAgencies() {
      return this.agency.establishments.filter(agency => agency.in_activity == 1)
    },
    selectedMembers() {
      const selectedMemberIds = []

      for (const collaboratorId of this.newTraining.collaborators) {
        for (const service of this.services) {
          for (const member of service.members) {
            if (member.id === collaboratorId) {
              selectedMemberIds.push(member.id)
            }
          }
        }
      }
      return selectedMemberIds
    },
  },
  methods: {
    handleUpdateCountCandidates() {},
    async sendInvitationMail() {
      async function postGuestAccount(email) {
        try {
          const formData = new FormData()
          formData.append("place", "addGuestAccount")
          formData.append("idMasterAccount", localStorage.getItem("master_account_linked"))
          formData.append("idAgency", this.$store.state.user.agency_id)
          formData.append("email", email)

          const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
            mode: "cors",
            method: "POST",
            body: formData,
          })

          let data = await response.json()

          if (data.code === 3) {
            return { idAccount: data.id_account, token: data.token }
          } else {
            throw new Error("Une erreur est survenue")
          }
        } catch (e) {
          console.log(e)
        }
      }

      // Utilisez une fonction itérative asynchrone pour introduire un délai entre chaque envoi d'e-mail
      async function sendEmailWithDelay(email) {
        try {
          let { idAccount, token } = await postGuestAccount(email)

          await emailjs.send("service_5p49t7p", "template_hkv4wtz", {
            to_email: email,
            link: `${config.appUri}/auth/login?firstconnection=1&id=${idAccount}&token=${token}`,
          })
        } catch (error) {
          console.log(error)
        }
      }

      for (const collaborator of this.emailList) {
        // Introduisez un délai de 4 secondes (4000 millisecondes) entre chaque envoi d'e-mail
        this.emailLoading = true
        await new Promise(resolve => setTimeout(resolve, 1000))

        await sendEmailWithDelay(collaborator).then(() => {
          this.emailLoading = false
        })
      }
    },
    verifyRights(tab) {
      // Admin a accès à tout
      if (this.user.grade === "administrateur") return true

      // if (g === "cabinet social" && tab === "office") return true

      // Manager a uniquement accès à Entretiens et Congés
      if (
        this.user.grade === "manager" &&
        (tab === "office-hollidays" || tab === "office-maintenances" || tab === "office-cost")
      )
        return true

      // Pour les autres cas, pas d'accès
      return false
    },
    recordMenu(tab) {
      //add url params to the url
      this.$router.push({
        query: {
          menu: tab,
        },
      })
    },
    async fetchManage() {
      this.newDemandCount = 0
      this.newCostCount = 0
      let isManager = localStorage.getItem("grade") === "manager"
      let idAccount = this.$store.state.user.id
      this.supervisedMembersIds = [] // Assurez-vous d'avoir cette ligne pour réinitialiser à chaque appel

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/manage`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        this.newDemandCount = data.account.count_total_holidays
        this.newCostCount = data.account.count_total_costs

        if (isManager) {
          this.supervisedAccount = data.account.service.map(service => {
            service.collaborators.forEach(collaborator => {
              this.supervisedMembersIds.push(collaborator.id) // Collecter les IDs
            })
            return {
              id: service.id,
              name: service.name,
              members: service.collaborators,
            }
          })
        }
      } catch (error) {
        console.error("Error:", error)
      }
    },
    getFormattedName(lastname, firstname) {
      lastname = lastname.toLowerCase().replace(/ /g, "")
      firstname = firstname.toLowerCase().replace(/ /g, "-")
      return `${lastname}-${firstname}`
    },
    tabChanged() {
      // Logique existante pour le changement d'onglet
      this.tabChangeIndicator++ // Incrément à chaque changement d'onglet
    },
    async testMergingTrainingDocuments() {
      //load document in hiding and get it

      this.agency.collaborators.forEach(async collaborator => {
        collaborator.allTrainings.forEach(async training => {
          if (training.training_attestation_url !== null) {
            let path = URL.createObjectURL(encodeURI(training.training_attestation_url))
            console.log(path)
          }
          if (training.training_module_url !== null) {
            let path2 = URL.createObjectURL(encodeURI(training.training_module_url))
            console.log(path2)
          }
        })
      })

      await merger.save("merged.pdf")
    },
    async generatePDFs() {
      const collaborators = this.agency.collaborators
      for (const collaborateur of collaborators) {
        const docDefinition = {
          pageOrientation: "landscape",
          content: [],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
          },
        }

        if (collaborateur.allTrainings.length > 0) {
          docDefinition.content.push({ text: `${collaborateur.firstname} ${collaborateur.lastname}`, style: "header" })

          for (const formation of collaborateur.allTrainings) {
            const formationData = [
              { text: "Organisme", style: "header" },
              { text: "Titre de la formation", style: "header" },
              { text: "Date", style: "header" },
              { text: "Durée", style: "header" },
            ]

            docDefinition.content.push(formationData)

            const formationRow = [
              formation.organism,
              formation.designation,
              formation.display_date,
              formation.display_duration,
            ]

            docDefinition.content.push(formationRow)

            // Download and embed the module_url PDF if available
            if (formation.training_module_url) {
              const modulePdf = await this.downloadPDF(encodeURI(formation.training_module_url))
              docDefinition.content.push(modulePdf)
            }

            // Download and embed the attestation_url PDF if available
            if (formation.training_attestation_url) {
              const attestationPdf = await this.downloadPDF(encodeURI(formation.training_attestation_url))
              docDefinition.content.push(attestationPdf)
            }

            docDefinition.content.push({ text: "", pageBreak: "after" })
          }
        }

        pdfMake.createPdf(docDefinition).download(`${collaborateur.lastname}_formations.pdf`)
      }
    },

    async downloadPDF(url) {
      return new Promise((resolve, reject) => {
        // Use an appropriate method to download the PDF from the URL
        // For example, you can use axios or fetch to download the PDF
        // Here's a simple example using fetch:
        fetch(url, { method: "GET", mode: "cors" })
          .then(response => response.blob())
          .then(blob => {
            // Create a data URL for embedding the PDF in the document
            const dataUrl = URL.createObjectURL(blob)
            const embeddedPdf = { image: dataUrl, width: 500 } // Adjust width as needed
            resolve(embeddedPdf)
          })
          .catch(error => {
            console.error(`Error downloading PDF from ${url}: ${error}`)
            reject(error)
          })
      })
    },

    openTrainingDocuments(item, id) {
      this.selectedTraining = item
      this.selectedTraining.id_document = id
      this.dialogTrainingDocuments = true
    },
    getTrainingsByYear(collabs) {
      let trainingsByYear = []

      collabs.forEach(collab => {
        let trainings = []
        let max_documents = 0
        let max_documents_completed = 0
        let resultDDA = "00:00:00"
        let resultNDDA = "00:00:00"
        let resultDDADone = "00:00:00"
        let resultByYear = "00:00:00"
        collab.organization.forEach(org => {
          org.training.forEach(training => {
            let training_documents = 0
            training.isDDA = training.isDDA
            training.year = org.year
            training.duration = moment(training.duration, "HH:mm:ss").format("HH:mm:ss")
            training.display_date = moment(training.date).format("Do MMM YYYY")
            training.display_duration = moment(training.duration, "HH:mm:ss").format("HH[h]mm")

            if (training.year == this.year) {
              max_documents += 2

              if (training.training_module_url != null) {
                max_documents_completed++
                training_documents++
              }

              if (training.training_attestation_url != null) {
                max_documents_completed++
                training_documents++
              }

              if (parseInt(training.isDDA) === 1) {
                resultDDA = this.additionnerHeures(resultDDA, training.duration)
                resultByYear = this.additionnerHeures(resultByYear, training.duration)

                if (training_documents > 0) {
                  resultDDADone = this.additionnerHeures(resultDDADone, training.duration)
                }
              } else {
                resultNDDA = this.additionnerHeures(resultNDDA, training.duration)
              }
              trainings.push(training)
            }
          })
        })
        trainingsByYear.push({
          id: collab.id,
          firstname: collab.firstname,
          lastname: collab.lastname,
          name: collab.lastname,
          allTrainings: trainings,
          sum_hours: this.formatDuration(this.additionnerHeures(resultDDA, resultNDDA)),
          sum_DDA_hours: this.formatDuration(resultDDA),
          sum_DDA_hours_done: this.formatDuration(resultDDADone),
          percentage: max_documents > 0 ? Math.round((max_documents_completed / max_documents) * 100, 2) : 0,
        })
      })

      return trainingsByYear
    },
    formatDuration(duration) {
      const dur = moment.duration(duration, "HH:mm:ss")
      const totalHours = Math.floor(dur.asHours()) // Obtient le total des heures
      const minutes = dur.minutes()

      return totalHours.toString().padStart(2, "0") + "h" + minutes.toString().padStart(2, "0")
    },
    getTrainingByYear(items) {
      let training = []
      items.forEach(item => {
        if (item.year == this.year) {
          training.push(item)
        }
      })
      return training
    },
    previousYear() {
      this.year = moment(this.year).subtract(1, "years").format("YYYY")
    },
    nextYear() {
      this.year = moment(this.year).add(1, "years").format("YYYY")
    },
    async update() {
      this.fetchAgency()
      this.fetchAgencyHoliday()
    },
    async fetchAgencyHoliday() {
      this.collaboratorsHoliday = []
      this.collaboratorsValidation = []
      this.demand = []
      this.test = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      let idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        const isManager = localStorage.getItem("grade") === "manager"

        if (isManager) {
          // Filtrer pour les managers
          // data.collaborators = data.collaborators.filter(collaborator =>
          //   this.supervisedMembersIds.includes(collaborator.id),
          // )

          data.demand = data.demand.filter(demand => this.supervisedMembersIds.includes(demand.id_account))

          data.history = data.history.filter(demand => this.supervisedMembersIds.includes(demand.id_account))
        }

        data.collaborators.forEach(collaborator => {
          collaborator.holidays.forEach(holiday => {
            // Mettre à jour les propriétés de l'objet holiday
            holiday.id = parseInt(holiday.id)
            holiday.id_account = parseInt(holiday.id_account)
            holiday.start_date = holiday.start_date
            holiday.end_date = holiday.end_date
            holiday.type = holiday.type
            holiday.number_of_days = parseFloat(holiday.number_of_days)
            holiday.state = parseInt(holiday.state)
            holiday.is_half_day = parseInt(holiday.is_half_day)
            holiday.half_day_type = parseInt(holiday.half_day_type)
            holiday.remain_paid_leave = parseInt(holiday.remain_paid_leave)
          })
          this.collaboratorsHoliday.push(collaborator)

          // collaborator.worked_days.forEach(day => {
          //   day.days = JSON.parse(day.days)
          //   day.days.forEach(workingDay => {
          //     workingDay.idWorkingDay = parseInt(day.id)
          //   })
          // })
        })

        data.collaborators.forEach(collaborator => {
          collaborator.worked_days.forEach(day => {
            if (parseInt(day.is_confirm) === 0) {
              day.days != null ? (day.days = JSON.parse(day.days)) : (day.days = [])
              day.days.forEach(workingDay => {
                workingDay.idWorkingDay = parseInt(day.id)
              })
            }
          })

          this.collaboratorsValidation.push(collaborator)
        })

        data.worked_days.forEach(m => {
          this.test.push(m)
        })

        data.demand.forEach(demand => {
          this.demand.push({
            id: demand.id,
            idAccount: demand.id_account,
            lastname: demand.lastname,
            firstname: demand.firstname,
            email: demand.email,
            number_of_days: parseFloat(demand.number_of_days),
            start_date: demand.start_date,
            end_date: demand.end_date,
            type: demand.type,
            state: parseInt(demand.state),
            date: demand.date,
            remain_paid_leave: demand.remain_paid_leave,
            recovery_working_time_leave: demand.recovery_working_time_leave,
            compensatory_package_leave: demand.compensatory_package_leave,
            remain_paid_leave_last_year: demand.remain_paid_leave_last_year,
            compensatory_package_leave_last_year: demand.compensatory_package_leave_last_year,
            recovery_working_time_leave_last_year: demand.recovery_working_time_leave_last_year,
            date_format: moment(demand.date).format("DD/MM/YYYY"),
            start_date_format: moment(demand.start_date).format("DD/MM/YYYY"),
            end_date_format: moment(demand.end_date).format("DD/MM/YYYY"),
            collaborator_comment: demand.collaborator_comment,
            is_half_day: parseInt(demand.is_half_day),
            half_day_type: parseInt(demand.half_day_type),
            resume: `${demand.lastname} ${demand.firstname} souhaite prendre  <strong>${
              demand.number_of_days
            }</strong> jour(s) de ${demand.type} du ${moment(demand.start_date).format("DD/MM/YYYY")} au ${moment(
              demand.end_date,
            ).format("DD/MM/YYYY")}`,
            days_remaining: parseFloat(demand.days_remaining),
            id_employment: parseInt(demand.employment_id),
          })
        })

        data.history.forEach(demand => {
          this.demandHistory.push({
            id: demand.id,
            lastname: demand.lastname,
            firstname: demand.firstname,
            complete_name: `${demand.lastname} ${demand.firstname}`,
            number_of_days: parseFloat(demand.number_of_days),
            start_date: demand.start_date,
            end_date: demand.end_date,
            type: demand.type,
            state: parseInt(demand.state),
            date: demand.date,
            remain_paid_leave: demand.remain_paid_leave,
            recovery_working_time_leave: demand.recovery_working_time_leave,
            compensatory_package_leave: demand.compensatory_package_leave,
            remain_paid_leave_last_year: demand.remain_paid_leave_last_year,
            compensatory_package_leave_last_year: demand.compensatory_package_leave_last_year,
            recovery_working_time_leave_last_year: demand.recovery_working_time_leave_last_year,
            date_format: moment(demand.date).format("DD/MM/YYYY"),
            start_date_format: moment(demand.start_date).format("DD/MM/YYYY"),
            end_date_format: moment(demand.end_date).format("DD/MM/YYYY"),
            collaborator_comment: demand.collaborator_comment,
            is_half_day: parseInt(demand.is_half_day),
            half_day_type: parseInt(demand.half_day_type),
            resume: `${demand.lastname} ${demand.firstname} souhaite prendre  <strong>${
              demand.number_of_days
            }</strong> jour(s) de ${demand.type} du ${moment(demand.start_date).format("DD/MM/YYYY")} au ${moment(
              demand.end_date,
            ).format("DD/MM/YYYY")}`,
            days_remaining: parseFloat(demand.days_remaining),
            id_employment: parseInt(demand.employment_id),
          })
        })

        // pause(4000)
      } catch (e) {
        console.log(e)
      }
    },
    handleServiceSelection(selectedServices) {
      // Réinitialisez newTraining.collaborators à un tableau vide
      this.newTraining.collaborators = []

      // Parcourez les services sélectionnés
      for (const service of selectedServices) {
        // Parcourez les membres du service et ajoutez leurs IDs à newTraining.collaborators
        for (const member of service.members) {
          this.newTraining.collaborators.push(member.id)
        }
      }
    },
    async verifyDocuments() {
      // Utilisez une fonction itérative asynchrone pour introduire un délai entre chaque envoi d'e-mail
      async function sendEmailWithDelay(collaborator, organization, training) {
        let obligatoryItems = []

        if (training.training_module_url === null || training.training_module_url === "") {
          obligatoryItems.push("Module de formation")
        }
        if (training.training_attestation_url === null || training.training_attestation_url === "") {
          obligatoryItems.push("Attestation de formation")
        }

        try {
          await emailjs.send("service_5p49t7p", "template_e4h8ozb", {
            firstname: collaborator.firstname,
            training_name: training.designation,
            date: training.date,
            documents: obligatoryItems,
            //from_name: email,
            to_email: collaborator.email,
          })
        } catch (error) {
          console.log(error)
        }
      }

      for (const collaborator of this.agency.collaborators) {
        for (const organization of collaborator.organization) {
          for (const training of organization.training) {
            // Introduisez un délai de 4 secondes (4000 millisecondes) entre chaque envoi d'e-mail
            await new Promise(resolve => setTimeout(resolve, 4000))
            await sendEmailWithDelay(collaborator, organization, training)
          }
        }
      }
    },

    // async verifyDocuments() {
    //   this.agency.collaborators.forEach(async collaborator => {
    //     collaborator.organization.forEach(async organization => {
    //       organization.training.forEach(async training => {
    //         await pause(4000)

    //         let obligatoryItems = []

    //         if (training.training_module_url === null || training.training_module_url === "") {
    //           obligatoryItems.push("Module de formation")
    //         }
    //         if (training.training_attestation_url === null || training.training_attestation_url === "") {
    //           obligatoryItems.push("Attestation de formation")
    //         }

    //         try {
    //           emailjs.send("service_5p49t7p", "template_e4h8ozb", {
    //             firstname: collaborator.firstname,
    //             training_name: training.designation,
    //             date: training.date,
    //             documents: obligatoryItems,
    //             //from_name: email,
    //             to_email: collaborator.email,
    //           })
    //         } catch (error) {
    //           console.log(error)
    //         }
    //       })
    //     })
    //   })
    // },
    async postTrainingComment(id, idAccount, description) {
      try {
        const formData = new FormData()

        formData.append("id", id)
        formData.append("description", description)
        formData.append("idAccount", idAccount)
        formData.append("place", "postComments")

        fetch(`${config.apiUri}/php/pages/account_training.php`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Formation modifiée avec succès", {
              position: "bottom-right",
            })
            this.fetchAgency()
          } else {
            this.$toast.error("Erreur lors de la modification de la formation", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async putTraining(item) {
      const idAccount = localStorage.getItem("user_id")
      try {
        // Construire le corps de la requête
        const requestBody = {
          date: item.date,
          duration: item.duration,
          designation: item.designation,
          organism: item.organism,
          isDDA: item.isDDA ? 1 : 0,
        }

        // Préparation des headers, notamment pour l'autorisation et le type de contenu
        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`) // Assurez-vous d'avoir un token valide

        // Appel de l'API avec la méthode PUT
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/trainings/${item.id}`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(requestBody),
        })

        if (response.ok) {
          this.$toast.success("Formation modifiée avec succès", {
            position: "bottom-right",
          })
          // Rafraîchir les données comme nécessaire
          this.fetchAgency()
          this.dialogEditTraining = false
        } else {
          this.$toast.error("Erreur lors de la modification de la formation", {
            position: "bottom-right",
          })
        }
      } catch (err) {
        console.log(err)
        this.$toast.error("Une erreur est survenue lors de la mise à jour de la formation", {
          position: "bottom-right",
        })
      }
    },
    uploadNewTraining(training, id) {
      try {
        const input = document.createElement("input")
        input.type = "file"
        input.accept =
          "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        input.addEventListener("change", event => {
          this.documentTraining.idTraining = training.id
          this.documentTraining.name = event.target.files[0].name
          this.documentTraining.type = id
          this.documentTraining.data = event.target.files[0]
          this.fileUrlTraining = URL.createObjectURL(this.documentTraining.data)
          this.dialogUploadDocument = true
          // this.uploadFile(event, type, area, subType)
        })
        input.click()
      } catch (err) {
        console.log(err)
      }
    },
    // uploadAttestation(training) {
    //   try {
    //     const input = document.createElement("input")
    //     input.type = "file"
    //     input.accept =
    //       "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //     input.addEventListener("change", event => {
    //       this.documentTraining.data = event.target.files[0]
    //       this.fileUrlTraining = URL.createObjectURL(this.documentTraining.data)
    //       this.dialogUploadDocument = true
    //       // this.uploadFile(event, type, area, subType)
    //     })
    //     console.log(input)
    //     console.log(this.documentTraining)
    //     input.click()
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    async postTrainingDocument(user) {
      const formData = new FormData()
      formData.append("document", this.documentTraining.data)
      formData.append("documentName", this.documentTraining.name)
      formData.append("username", user.username)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("idTraining", this.documentTraining.idTraining)
      this.documentTraining.type === 1 ? formData.append("place", "addTrainingModule") : null
      this.documentTraining.type === 2 ? formData.append("place", "addTrainingAttestation") : null

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          this.dialogUploadDocument = false

          this.documentTraining = {
            name: "",
            data: {
              name: null,
              type: null,
            },
            categorie: null,
          }

          this.editedUploadTraining = {
            lastname: "",
            firstname: "",
          }

          this.fetchAgency()

          this.dialogTrainingDocuments = false
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    async deleteTraining(id) {
      const idAccount = localStorage.getItem("user_id")

      fetch(`${config.apiUri}/accounts/${idAccount}/trainings/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
      })
        .then(response => {
          if (response.ok) {
            this.fetchAgency()
            this.$toast.success("Formation supprimée avec succès")
          } else {
            this.$toast.error("Erreur lors de la suppression de la formation")
          }
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    getYearId(year) {
      let result
      this.agency.years.forEach(item => {
        if (item.year == year) {
          result = item.id
        }
      })
      return result
    },
    async postNewTraining() {
      const YEAR = this.getYearId(moment(this.newTraining.date).format("YYYY"))

      try {
        const postData = {
          idAccountTrainingYear: YEAR,
          idAccounts: JSON.stringify(this.newTraining.collaborators),
          date: this.newTraining.date,
          duration: this.newTraining.duration,
          designation: this.newTraining.designation,
          organism: this.newTraining.organism,
          isDDA: this.newTraining.isDDA ? 1 : 0,
          isHalfDay: this.newTraining.isHalfDay ? 1 : 0,
          halfDayType: this.newTraining.isHalfDay ? this.newTraining.halfDayType : 0,
        }

        fetch(`${config.apiUri}/accounts/trainings`, {
          method: "POST",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            // this.uploadTraining(this.selected.completeName)
            // this.$toast.success("Formation ajoutée avec succès")
            // this.initialize()
            // this.fetchUsers()
            this.dialogAddTraining = false
            this.currentIdAccount = null

            this.newTraining = {
              collaborators: [],
              date: "",
              isDDA: false,
              designation: "",
              duration: "",
              organism: "",
            }
            this.$toast.success("Formation ajoutée avec succès")
            this.update()
          } else {
            this.$toast.error("Erreur lors de l'ajout de la formation")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getProgressColor(percentage) {
      if (percentage === 100) {
        return "EoleBlue"
      } else {
        return "EoleYellow"
      }
    },
    async fetchAgencyFile() {
      let headers = new Headers()
      let agencyId = this.$store.state.user.agency_id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (parseInt(data.code) === 1) {
          this.agency.files = data.agency[0].files
        }
      } catch (e) {
        console.log(e)
      }
    },
    editItem(item) {
      this.editServiceManage = item
      this.dialogEditService = true
    },
    handleScroll(event) {
      if (event.deltaY < 0) {
        // Faites quelque chose ici pour exécuter la fonction ZoomOut
        // par exemple, appelez directement la fonction ou émettez un événement personnalisé
        this.zoomIn()
      } else if (event.deltaY > 0) {
        // Faites quelque chose ici pour exécuter la fonction ZoomIn
        // par exemple, appelez directement la fonction ou émettez un événement personnalisé
        this.zoomOut()
      }
    },
    async postCollaboratorInService(idService, collaborators) {
      const formData = new FormData()
      formData.append("idService", idService)
      formData.append("collaborators", JSON.stringify(collaborators))
      formData.append("idAgency", this.$store.state.user.agency_id)

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/services.php?place=addCollaborators`, {
        method: "POST",
        body: formData,
      })
        .finally(() => {
          this.fetchServices()
          this.fetchServicesMembers(idService, this.services.length - 1)
          this.$toast.success("Collaborateur ajouté avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    deleteServiceCollaborator(idService, idCollaborator) {
      let idAgency = this.$store.state.user.agency_id
      fetch(
        `${config.apiUri}/php/pages/services.php?idAgency=${idAgency}&idService=${idService}&place=deleteCollaborator&idCollaborator=${idCollaborator}`,
        {
          method: "DELETE",
        },
      )
        .finally(() => {
          this.fetchServices()
          this.$toast.success("Collab supprimé avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    deleteService(id) {
      let idAgency = this.$store.state.user.agency_id
      fetch(`${config.apiUri}/php/pages/services.php?idAgency=${idAgency}&idService=${id}&place=deleteService`, {
        method: "DELETE",
      })
        .finally(() => {
          this.fetchServices()
          this.$toast.success("Service supprimé avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },

    putService(item) {
      const formData = new FormData()
      // formData.append("idService", this.editService.idService)
      formData.append("name", item.name)
      formData.append("idService", item.id_service)
      formData.append("idManager", item.id_manager)
      formData.append("idAgencyEtablishment", item.id_agency_etablishment)
      formData.append("idAgency", this.$store.state.user.agency_id)

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/services.php?place=editService`, {
        method: "POST",
        body: formData,
      })
        .finally(() => {
          this.fetchServices()
          this.dialogPutServiceManage = false
          this.$toast.success("Service mdifier avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    postService() {
      const formData = new FormData()
      // formData.append("idService", this.editService.idService)
      formData.append("name", this.editService.name)
      formData.append("idManager", this.editService.idManager)
      formData.append("idAgencyEtablishment", this.editService.idAgencyEtablishment)
      formData.append("idAgency", this.$store.state.user.agency_id)

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/services.php?place=addService`, {
        method: "POST",
        body: formData,
      })
        .finally(() => {
          this.fetchServices()
          this.dialogAddService = false
          this.stepperAddService = 1
          this.$toast.success("Service ajouté avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
          this.stepperAddService = 1
        })

      this.editService = {
        // idService: null,*
        name: "",
        idManager: null,
        idAgencyEtablishment: null,
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    importPutFile(file, type, area, subType) {
      const input = document.createElement("input")
      input.type = "file"
      input.accept =
        "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      input.addEventListener("change", event => {
        this.editFile = file
        this.document.data = event.target.files[0]
        file.id_agency_etablishment === null
          ? (this.document.id_agency_etablishment = 999)
          : (this.document.id_agency_etablishment = file.id_agency_etablishment)
        this.fileUrl = URL.createObjectURL(this.document.data)
        let splitName = this.document.data.name.split(".")
        this.document.title = splitName[0]
        this.document.extension = splitName[1]
        this.dialogUpload.dialog = true
        this.dialogUpload.type = type
        this.dialogUpload.area = area
        this.dialogUpload.subType = subType

        // this.uploadFile(event, type, area, subType)
      })
      input.click()
    },
    getObligatoryInformations() {
      this.obligatoryInformations.max = 3

      this.agencyPictureProfil != null
        ? this.obligatoryInformations.value++
        : this.obligatoryInformations.fields.push("Logo de l'agence")
      this.agency.legal_mention_middleman != null && this.agency.legal_mention_middleman != ""
        ? this.obligatoryInformations.value++
        : this.obligatoryInformations.fields.push("Mention légale courtier")
      this.agency.legal_mention_agent != null && this.agency.legal_mention_agent != ""
        ? this.obligatoryInformations.value++
        : this.obligatoryInformations.fields.push("Mention légale agent")

      this.obligatoryInformations.percentage = Math.round(
        (this.obligatoryInformations.value / this.obligatoryInformations.max) * 100,
        2,
      )
    },
    importFile(type, area, subType) {
      const input = document.createElement("input")
      input.type = "file"
      input.accept =
        "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      input.addEventListener("change", event => {
        this.document.data = event.target.files[0]
        this.documentArea.type = type
        this.documentArea.area = area
        this.documentArea.subType = subType
        this.fileUrl = URL.createObjectURL(this.document.data)
        this.dialogUploadPostFile = true

        // this.uploadFile(event, type, area, subType)
      })
      input.click()
    },
    openFileExplorer() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/png, image/jpeg"
      input.addEventListener("change", this.uploadPicture)
      input.click()
    },
    getPictureProfil() {
      try {
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/agency/profil.png?${Date.now()}`
        let pictureProfilJpg = `${config.ged}/${agency}/agency/profil.jpg?${Date.now()}`

        let img = new Image()
        img.onload = () => {
          this.agencyPictureProfil = img.src
        }
        img.onerror = () => {
          this.agencyPictureProfil = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.agencyPictureProfil == null) {
            img.src = pictureProfilJpg
          }

          this.obligatoryInformations = {
            value: 0,
            max: 0,
            percentage: 0,
            fields: [],
          }
          this.getObligatoryInformations()
        }, 500)
      } catch (error) {
        this.agencyPictureProfil = null
      }

      // return url with picture profil
    },

    async putFileSetShareable(idFile, value) {
      value === true ? (value = 1) : (value = 0)
      try {
        const res = await fetch(`${config.apiUri}/php/pages/agencies_file.php?idFile=${idFile}&share=${value}`, {
          method: "PUT",
        })
        if (res.ok) {
          if (value === 1) {
            this.socket = io(config.socketUri)
            this.socket.emit("notificationAll", {
              type: 3,
              info: {
                title: `Nouveau document disponible`,
                datetime: new Date(),
                action: `Un nouveau document a été partagé avec vous. Vous pouvez le consulter dans votre espace d'entreprise`,
              },
            })
            this.$toast.success("Fichier partagé avec succès", {
              position: "bottom-right",
              timeout: 5000,
            })
          } else {
            this.$toast.success("Fichier non partagé avec succès", {
              position: "bottom-right",
              timeout: 5000,
            })
          }
        } else {
          this.$toast.success("Erreur dans le partage du fichier", {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (err) {
        console.error(err)
      }
    },

    async deleteFile(idFile, path) {
      try {
        const res = await fetch(`${config.apiUri}/php/pages/agencies_file.php?idFile=${idFile}&path=${path}`, {
          method: "DELETE",
        })
        if (res.ok) {
          this.$toast.success("Fichier supprimé avec succès")
          this.fetchAgency()
        } else {
          alert("Erreur lors de la suppression du fichier")
        }
      } catch (err) {
        console.error(err)
      }
    },
    async uploadPutFile(event, type, area, subType = null, idFile) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.title)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "agency")
      formData.append("type", type)
      formData.append("area", area)
      formData.append("idAgency", this.$store.state.user.agency_id)
      formData.append("idFile", idFile)
      formData.append("methods", "PUT")
      subType != null ? formData.append("subType", subType) : null

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        }).then(res => {
          if (res.ok) {
            this.document = {
              name: "",
              data: null,
              categorie: null,
              id_agency_etablishment: 999,
            }

            this.fetchAgency()
            this.dialogUpload = {
              dialog: false,
              type: 0,
              area: "",
              subType: "",
            }
          } else {
            alert("Erreur lors de l'upload du PDF")
          }
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    async uploadFile(event, type, area, subType = null) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.name)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "agency")
      formData.append("type", type)
      formData.append("area", area)
      formData.append("idAgency", this.$store.state.user.agency_id)
      area === "affichage_obligatoire"
        ? formData.append("idAgencyEtablishment", this.document.id_agency_etablishment)
        : null
      subType != null ? formData.append("subType", subType) : null

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        }).then(res => {
          if (res.ok) {
            this.document = {
              name: "",
              data: null,
              categorie: null,
              id_agency_etablishment: 999,
            }

            this.fetchAgency()
            this.dialogUploadPostFile = false
          } else {
            alert("Erreur lors de l'upload du PDF")
          }
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    async uploadPicture() {
      const file = event.target.files[0]
      this.loadImage = true
      this.document.data = file
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
          id_agency_etablishment: 999,
        }
      } else {
        switch (this.document.data.type) {
          case "image/png":
          case "image/jpeg":
            const id = localStorage.getItem("user_id")
            const username = localStorage.getItem("complete_name").replace(" ", "-")
            const formData = new FormData()
            formData.append("document", this.document.data)
            formData.append("documentName", "profil")
            formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
            formData.append("place", "agency")

            try {
              fetch(`${config.apiUri}/upload`, {
                method: "POST",
                body: formData,
              })
                .then(res => {
                  if (res.ok) {
                    this.dialogUpload = false
                    this.document = {
                      name: "",
                      data: null,
                      categorie: null,
                      id_agency_etablishment: 999,
                    }
                  } else {
                    alert("Erreur lors de l'upload de l'image")
                  }
                })
                .finally(() => {
                  setTimeout(() => {
                    // location.reload()
                    this.loadImage = false
                    this.getPictureProfil()
                  }, 2000)
                })
            } catch (err) {
              console.error(err)
              alert("Erreur lors de l'upload de l'image")
            }

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    async fetchAllServices() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)
      try {
        const res = await fetch(`${config.apiUri}/services`, {
          method: "GET",
          headers: headers,
        })
        const data = await res.json()

        this.servicesProposed = data
      } catch (err) {
        console.error(err)
      }
    },
    async putAgencyLegalNotice(type) {
      const formData = new FormData()
      formData.append("place", "updateLegalNotice")
      formData.append(
        "legalNotice",
        type === "agent" ? this.agency.legal_mention_agent : this.agency.legal_mention_middleman,
      )
      formData.append("type", type)

      try {
        await fetch(`${config.apiUri}/agencies/${this.agency.id}`, {
          method: "POST",
          body: formData,
        }).finally(() => {
          this.fetchAgency()

          this.$toast.success("Mention légale mise à jour avec succès", {
            position: "bottom-right",
            timeout: 5000,
          })
        })
      } catch (err) {
        console.error(err)

        this.$toast.error("Impossible de mettre les mentions légales à jour.", {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    redirect() {
      // redirect in new tab

      window.open("https://www.tresor.economie.gouv.fr/services-aux-entreprises/sanctions-economiques", "_blank")
    },
    selectTabProcess(tab) {
      this.tabProcess = tab
    },
    selectTabHR(tab) {
      this.tabHR = tab
    },
    setFile(e) {
      this.pdf = e.target.files[0]
    },
    async uploadPDF() {
      const formData = new FormData()
      formData.append("pdf", this.pdf)

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          alert("PDF uploadé avec succès")
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    zoomIn() {
      this.$refs.tree.zoomIn()
    },
    zoomOut() {
      this.$refs.tree.zoomOut()
    },
    showMap(adress) {
      let routeData = this.$router.resolve({
        name: `https://www.google.fr/maps/place/${adress}`,
      })
      window.open(routeData.location.name, "_blank")
    },

    // async fetchCollectiveAgreements(siret) {
    //   let headers = new Headers()

    //   headers.append("Content-Type", "application/json charset=UTF-8")
    //   headers.append("Accept", "application/json")
    //   headers.append("Origin", "*")

    //   try {
    //     let response = await fetch(`https://siret2idcc.fabrique.social.gouv.fr/api/v2/${siret}`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })

    //     const data = await response.json()

    //     this.collectiveAgreements = data[0].conventions
    //     if (this.collectiveAgreements.length === 0) {
    //       this.collectiveAgreements = [
    //         {
    //           id: 0,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //         {
    //           id: 1,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //         {
    //           id: 2,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //         {
    //           id: 3,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //       ]
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    sortFiles() {
      this.obligatoryFilesCount = {
        value: 0,
        max: 0,
        percentage: 0,
      }
      this.agency.files.forEach(file => {
        switch (parseInt(file.id)) {
          case 1:
            file.files.forEach(item => {
              this.agency.contracts.push(item)
              this.agency.contracts.forEach(contract => {
                contract.format_date_upload = moment(contract.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                contract.share_acpr = parseInt(contract.share_acpr)
                contract.share = parseInt(contract.share)
              })
            })
            break
          case 2:
            file.files.forEach(item => {
              this.agency.charts.push(item)
              this.agency.charts.forEach(chart => {
                chart.format_date_upload = moment(chart.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                chart.share_acpr = parseInt(chart.share_acpr)
                chart.share = parseInt(chart.share)
              })
            })
            break
          case 3:
            file.files.forEach(item => {
              this.agency.conventions.push(item)
              this.agency.conventions.forEach(convention => {
                convention.format_date_upload = moment(convention.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                convention.share_acpr = parseInt(convention.share_acpr)
                convention.share = parseInt(convention.share)
              })
            })
            break
          case 4:
            file.files.forEach(item => {
              this.agency.decision.push(item)
              this.agency.decision.forEach(decision => {
                decision.format_date_upload = moment(decision.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                decision.share_acpr = parseInt(decision.share_acpr)
                decision.share = parseInt(decision.share)
              })
            })
            break
          case 5:
            file.files.forEach(item => {
              item.path != null ? this.obligatoryFilesCount.value++ : null

              this.agency.display.push(item)
              this.agency.display.forEach(display => {
                display.format_date_upload = moment(display.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                display.share_acpr = parseInt(display.share_acpr)
                display.share = parseInt(display.share)
              })

              this.obligatoryFilesCount.max++
              this.obligatoryFilesCount.percentage = Math.round(
                (this.obligatoryFilesCount.value / this.obligatoryFilesCount.max) * 100,
                2,
              )
            })

            break
          case 6:
            file.files.forEach(item => {
              this.agency.announce.push(item)
              this.agency.announce.forEach(announce => {
                announce.format_date_upload = moment(announce.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                announce.share_acpr = parseInt(announce.share_acpr)
                announce.share = parseInt(announce.share)
              })
            })
            break
          case 7:
            file.files.forEach(item => {
              this.agency.recruit.push(item)
              this.agency.recruit.forEach(recruit => {
                recruit.format_date_upload = moment(recruit.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                recruit.share_acpr = parseInt(recruit.share_acpr)
                recruit.share = parseInt(recruit.share)
              })
            })
            break
          case 8:
            file.files.forEach(item => {
              this.agency.poste.push(item)
              this.agency.poste.forEach(poste => {
                poste.format_date_upload = moment(poste.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                poste.share_acpr = parseInt(poste.share_acpr)
                poste.share = parseInt(poste.share)
              })
            })
            break
          case 9:
            file.files.forEach(item => {
              this.agency.money_accord.push(item)
              this.agency.money_accord.forEach(money_accord => {
                money_accord.format_date_upload = moment(money_accord.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
                money_accord.share_acpr = parseInt(money_accord.share_acpr)
                money_accord.share = parseInt(money_accord.share)
              })
            })
            break
          case 10:
            file.files.forEach(item => {
              this.agency.participation_accord.push(item)
              this.agency.participation_accord.forEach(participation_accord => {
                participation_accord.format_date_upload = moment(participation_accord.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
                participation_accord.share_acpr = parseInt(participation_accord.share_acpr)
                participation_accord.share = parseInt(participation_accord.share)
              })
            })
            break
          case 11:
            file.files.forEach(item => {
              this.agency.reclamations.push(item)
              this.agency.reclamations.forEach(reclamation => {
                reclamation.format_date_upload = moment(reclamation.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                reclamation.share_acpr = parseInt(reclamation.share_acpr)
                reclamation.share = parseInt(reclamation.share)
              })
            })
            break
          case 12:
            file.files.forEach(item => {
              this.agency.lcbft.push(item)
              this.agency.lcbft.forEach(lcbft => {
                lcbft.format_date_upload = moment(lcbft.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 13:
            file.files.forEach(item => {
              this.agency.ppe.push(item)
              this.agency.ppe.forEach(ppe => {
                ppe.format_date_upload = moment(ppe.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 14:
            file.files.forEach(item => {
              this.agency.dda.push(item)
              this.agency.dda.forEach(dda => {
                dda.format_date_upload = moment(dda.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 15:
            file.files.forEach(item => {
              this.agency.rgpd.push(item)
              this.agency.rgpd.forEach(rgpd => {
                rgpd.format_date_upload = moment(rgpd.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 16:
            file.files.forEach(item => {
              this.agency.gda.push(item)
              this.agency.gda.forEach(gda => {
                gda.format_date_upload = moment(gda.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 17:
            file.files.forEach(item => {
              item.path != null ? this.obligatoryFilesCount.value++ : null

              this.agency.agency.push(item)
              this.agency.agency.forEach(display => {
                display.format_date_upload = moment(display.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                display.share_acpr = parseInt(display.share_acpr)
                display.share = parseInt(display.share)
              })

              this.obligatoryFilesCount.max++
              this.obligatoryFilesCount.percentage = Math.round(
                (this.obligatoryFilesCount.value / this.obligatoryFilesCount.max) * 100,
                2,
              )
            })

            break
          case 18:
            file.files.forEach(item => {
              this.agency.endorsement_money_accord.push(item)
              this.agency.endorsement_money_accord.forEach(endorsement_money_accord => {
                endorsement_money_accord.format_date_upload = moment(endorsement_money_accord.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
              })
            })
            break
          case 19:
            file.files.forEach(item => {
              this.agency.social_statement.push(item)
              this.agency.social_statement.forEach(social_statement => {
                social_statement.format_date_upload = moment(social_statement.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
              })
            })
            break
          case 20:
            file.files.forEach(item => {
              this.agency.monthly_status.push(item)
              this.agency.monthly_status.forEach(social_statement => {
                social_statement.format_date_upload = moment(social_statement.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
              })
            })
            break
          case 21:
            file.files.forEach(item => {
              this.agency.products.push(item)
              this.agency.products.forEach(product => {
                product.format_date_upload = moment(product.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
        }
      })
    },
    additionnerHeures(heure1, heure2) {
      const format = "HH:mm:ss"
      const moment1 = moment.duration(heure1, format)
      const moment2 = moment.duration(heure2, format)

      // Convertir chaque durée en minutes et les additionner
      const minutesTotales = moment1.asMinutes() + moment2.asMinutes()

      // Convertir les minutes totales en heures et minutes
      const heures = Math.floor(minutesTotales / 60)
      const minutes = Math.floor(minutesTotales % 60)

      // Construire la chaîne de caractères finale
      let resultat = heures.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0")

      return resultat
    },
    additionnerDureeEtHeure(duree, heure) {
      const format = "HH:mm:ss"
      const regex = /(\d+)\s+jour\(s\)\s+(\d+)h(\d+)/ // Pour matcher "X jour(s) YhZ"

      // Extraire les jours, les heures et les minutes de la durée
      const [, jours, heuresDuree, minutesDuree] = duree.match(regex) || []

      // Convertir en objet moment
      let momentDuree = moment.duration({
        days: parseInt(jours),
        hours: parseInt(heuresDuree),
        minutes: parseInt(minutesDuree),
      })

      // Convertir l'heure en objet moment
      let momentHeure = moment.duration(moment(heure, format).diff(moment("00:00:00", format)))

      // Additionner les deux durées
      momentDuree.add(momentHeure)

      // Convertir le résultat en jours, heures, minutes
      const totalJours = Math.floor(momentDuree.asDays())
      const totalHeures = momentDuree.hours()
      const totalMinutes = momentDuree.minutes()
      const totalSecondes = momentDuree.seconds()

      // Formatage du résultat
      let resultat = [totalHeures, totalMinutes, totalSecondes].map(unit => String(unit).padStart(2, "0")).join(":")

      if (totalJours > 0) {
        resultat = totalJours + " jour(s) " + moment(resultat, "HH:mm").format("HH[h]mm")
      }

      return resultat
    },

    // sum function for the number of members
    async fetchAgency() {
      this.dataFetch = true
      this.collaboratorsCompensatoryLeave = []
      this.collaboratorsRecoveryLeave = []

      this.agency = []

      let headers = new Headers()
      let agencyId = this.$store.state.user.agency_id

      agencyId == undefined ? (agencyId = this.$store.state.user.id_agency) : null

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*") * headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          this.agency = data.agency[0]

          this.agency.contracts = []

          this.agency.charts = []

          this.agency.conventions = []

          this.agency.decision = []

          this.agency.display = []

          this.agency.announce = []

          this.agency.recruit = []

          this.agency.poste = []

          this.agency.money_accord = []

          this.agency.participation_accord = []

          this.agency.reclamations = []

          this.agency.lcbft = []

          this.agency.ppe = []

          this.agency.dda = []

          this.agency.rgpd = []

          this.agency.gda = []

          this.agency.agency = []

          this.agency.endorsement_money_accord = []

          this.agency.social_statement = []

          this.agency.monthly_status = []

          this.agency.products = []

          this.agencyInformations = {
            recovery_current_year: parseFloat(this.agency.recovery_current_year),
            compensatory_current_year: parseFloat(this.agency.compensatory_current_year),
          }

          this.sortFiles()
          this.agency.collaborators.forEach(collaborator => {
            if (parseInt(collaborator.employment_id) === 2 && parseInt(collaborator.disabled) === 0) {
              this.collaboratorsCompensatoryLeave.push({
                id: collaborator.id,
                lastname: collaborator.lastname,
                firstname: collaborator.firstname,
              })
            } else if (parseInt(collaborator.employment_id) != 2 && parseInt(collaborator.disabled) === 0) {
              this.collaboratorsRecoveryLeave.push({
                id: collaborator.id,
                lastname: collaborator.lastname,
                firstname: collaborator.firstname,
              })
            }

            collaborator.birthdate_display = moment(collaborator.birth_date).format("Do MMM YYYY")
            collaborator.nationality != null
              ? (collaborator.nationality_abbreviation = collaborator.nationality.substring(0, 2).toLowerCase())
              : null

            collaborator.disabled = parseInt(collaborator.disabled)
            //random number between 1 and 100
            let max_documents = 0
            let max_documents_completed = 0
            let resultDDA = "00:00:00"
            let resultNDDA = "00:00:00"
            let resultDDADone = "00:00:00"
            let allTrainings = []
            collaborator.organization.forEach(element => {
              let resultByYear = "00:00:00"
              element.training.forEach(training => {
                let training_documents = 0
                training.isDDA = parseInt(training.isDDA)
                training.year = element.year
                training.duration = moment(training.duration, "HH:mm:ss").format("HH:mm")
                training.display_date = moment(training.date).format("Do MMM YYYY")
                training.display_duration = moment(training.duration, "HH:mm:ss").format("HH[h]mm")

                max_documents += 2

                if (training.training_module_url != null) {
                  max_documents_completed++
                  training_documents++
                }

                if (training.training_attestation_url != null) {
                  max_documents_completed++
                  training_documents++
                }

                if (parseInt(training.isDDA) === 1) {
                  // resultDDA = this.additionnerHeures(resultDDA, training.duration)

                  resultDDA.includes("jour(s)")
                    ? (resultDDA = this.additionnerDureeEtHeure(resultDDA, training.duration))
                    : (resultDDA = this.additionnerHeures(resultDDA, training.duration))

                  resultByYear = this.additionnerHeures(resultByYear, training.duration)

                  if (training_documents > 0) {
                    resultDDADone.includes("jour(s)")
                      ? (resultDDADone = this.additionnerDureeEtHeure(resultDDADone, training.duration))
                      : (resultDDADone = this.additionnerHeures(resultDDADone, training.duration))
                  }
                } else {
                  resultNDDA = this.additionnerHeures(resultNDDA, training.duration)
                }

                allTrainings.push(training)
              })
              resultByYear.includes("jour(s)")
                ? (element.sum_year = resultByYear)
                : (element.sum_year = moment(resultByYear, "HH:mm:ss").format("HH[h]mm"))
            })

            collaborator.allTrainings = allTrainings

            // collaborator.sum_DDA_hours_done = moment(resultDDADone, "HH:mm:ss").format("HH[h]mm")

            resultDDADone.includes("jour(s)")
              ? (collaborator.sum_DDA_hours_done = resultDDADone)
              : (collaborator.sum_DDA_hours_done = moment(resultDDADone, "HH:mm:ss").format("HH[h]mm"))

            // if (max_documents_completed > 0) {
            //   collaborator.sum_DDA_hours_done = moment(resultDDADone, "HH:mm:ss").format("HH[h]mm")
            // } else {
            //   collaborator.sum_DDA_hours_done = "00h00"
            // }

            max_documents > 0
              ? (collaborator.percentage = Math.round((max_documents_completed / max_documents) * 100, 2))
              : (collaborator.percentage = 0)

            resultDDA.includes("jour(s)")
              ? (collaborator.sum_DDA_hours = resultDDA)
              : (collaborator.sum_DDA_hours = moment(resultDDA, "HH:mm:ss").format("HH[h]mm"))

            resultDDA.includes("jour(s)")
              ? (collaborator.sum_hours = this.additionnerDureeEtHeure(resultDDA, resultNDDA))
              : (collaborator.sum_hours = moment(this.additionnerHeures(resultDDA, resultNDDA), "HH:mm:ss").format(
                  "HH[h]mm",
                ))
          })
          this.agency.activeCollaborators = this.agency.collaborators.filter(collaborator => {
            return parseInt(collaborator.disabled) === 0
          })

          this.agency.inactiveCollaborators = this.agency.collaborators.filter(collaborator => {
            return parseInt(collaborator.disabled) === 1
          })

          this.obligatoryInformations = {
            value: 0,
            max: 0,
            percentage: 0,
            fields: [],
          }
          this.getObligatoryInformations()
          this.agency.establishments.forEach(etablishment => {
            this.etablishmentsOptions.push({
              id: etablishment.id,
              name: etablishment.city,
            })
          })
        }

        this.agency.creation_date_formated = moment(this.agency.creation_date).format("Do MMM YYYY")
        this.dataFetch = false

        this.countCandidates = this.agency.candidates.length
      } catch (e) {
        console.log(e)
      }
    },

    async fetchServices() {
      this.services = []
      this.nodeChart = []
      this.fetchServicesState = true
      let headers = new Headers()
      let idAgency = this.$store.state.user.agency_id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      await pause(1500)

      try {
        let response

        response = await fetch(`${config.apiUri}/agencies/${idAgency}/services`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.services.forEach(element => {
            let constructChildren = []
            // element.members.map(async collaborator => {
            //   collaborator.avatarUrl = await this.getImg2(collaborator.lastname, collaborator.firstname)
            // })
            element.members.map(async member => {
              member.avatarUrl = await this.getImg2(member.lastname, member.firstname)

              constructChildren.push({
                name: `${member.lastname} ${member.firstname}`,
                avatarUrl: member.avatarUrl,
                description: "Collaborateur",
                avatarUrl: member.avatarUrl,
              })
            })

            let manager = [
              {
                name: `${element.lastname_manager} ${element.firstname_manager}`,
                description: "Responsable hiérarchique",
                children: constructChildren,
                avatarUrl: element.avatarUrl,
              },
            ]

            element.chartData = {
              name: `${element.name}`,
              description: "Service",
              children: manager,
            }

            this.services.push({
              id_service: element.id_service,
              id_service_manager: element.id_service_manager,
              name: element.name,
              id_manager: element.id_manager,
              lastname_manager: element.lastname_manager,
              firstname_manager: element.firstname_manager,
              complete_name_manager: `${element.lastname_manager} ${element.firstname_manager}`,
              number_members: 0,
              location: element.location,
              id_agency_etablishment: element.id_agency_etablishment,
              members: element.members,
              chartData: element.chartData,
            })

            this.fetchServicesMembers(element.id_service_manager, this.services.length - 1)
          })
        }
        await pause(3000)
        this.chartData = {
          name: this.agencyName,
          description: "Mon cabinet",
          children: this.nodeChart,
        }
        if (this.services.length > 0) {
          this.valueOrganization = 100
        } else {
          this.valueOrganization = 0
        }
        this.fetchServicesState = false
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchServicesMembers(idService, index) {
      this.fetchMembers = true
      await pause(1500)

      let headers = new Headers()
      let idAgency = this.$store.state.user.agency_id
      let response

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      response = await fetch(`${config.apiUri}/agencies/${idAgency}/services/${idService}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      })

      const data = await response.json()

      this.services[index].number_members = parseInt(data.count_members_total)

      let constructChildren = []
      data.members.map(async member => {
        constructChildren.push({
          name: `${member.lastname} ${member.firstname}`,
          contract: member.contract[0],
          description: "Collaborateur",
          avatarUrl: await this.getImg2(member.lastname, member.firstname),
        })
      })

      let manager = [
        {
          name: `${this.services[index].complete_name_manager}`,
          description: "Responsable hiérarchique",
          avatarUrl: await this.getImg2(this.services[index].lastname_manager, this.services[index].firstname_manager),

          children: constructChildren,
        },
      ]

      this.nodeChart.push({
        name: `${this.services[index].name}`,
        description: "Service",
        children: manager,
      })

      this.fetchMembers = false
    },
  },
}
</script>

<style>
.icon-medium {
  font-size: 1.2rem;
}

.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .v-data-table tr {
    text-align: center !important;
  }
  
  .theme--light.header-table th {
  
    font-size: 14px !important;
    text-align: center !important;
  }
  
  .theme--light.header-table td {
    text-align: center !important;
  } */

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.hide-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.row-collaborators {
  max-width: 900px;
}

.row-etablishment {
  max-width: 450px;
}

.hover-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}

.avatar:hover .hover-overlay {
  opacity: 1;
}

.avatar-skeleton {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.overlay-text {
  color: white;
  font-size: 20px !important;
  text-align: center;
}

.bordered {
  border: 1.5px solid #e0e0e0;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 1;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -57px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

.test {
  /*  remove bg color */
  background-color: transparent !important;
}
</style>
